var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":""}},[_c('v-form',{ref:"form"},[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('input',{ref:"file",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onChange}}),_c('v-card',{staticClass:"mx-auto mt-0 d-inline-flex v-card--account",class:_vm.model.image ? 'success--text' : 'grey--text',attrs:{"outlined":"","height":"106","width":"106"},on:{"click":function($event){return _vm.$refs.file.click()}}},[(_vm.model.image)?_c('v-img',{staticClass:"custom-avatar",attrs:{"src":_vm.model.image,"height":"100%","width":"100%"}}):_c('v-icon',{staticClass:"mx-auto",attrs:{"size":"96"}},[_vm._v("mdi-account")])],1),_c('div',{staticClass:"font-weight-bold grey--text"},[_vm._v("Choose Picture")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Firts Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First Name","error-messages":errors,"color":"secondary","validate-on-blur":""},model:{value:(_vm.model.first_name),callback:function ($$v) {_vm.$set(_vm.model, "first_name", $$v)},expression:"model.first_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last Name","error-messages":errors,"color":"secondary","validate-on-blur":""},model:{value:(_vm.model.last_name),callback:function ($$v) {_vm.$set(_vm.model, "last_name", $$v)},expression:"model.last_name"}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Notification email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Notification email","error-messages":errors,"color":"secondary","validate-on-blur":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Phone","error-messages":errors,"color":"secondary","validate-on-blur":"","type":"number"},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}])})],1)],1)],1)],1),_c('v-btn',{attrs:{"dark":"","link":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading,"disabled":!_vm.model.first_name || !_vm.model.last_name || !_vm.model.email},on:{"click":_vm.save}},[_vm._v("Save")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }