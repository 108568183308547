<template lang="pug">
  v-card
    v-card-title
      | Update Profile
      v-spacer
        v-icon(aria-label="Close" @click="$emit('close')")
          | mdi-close
    v-card-text.body-1.text-center
      v-row
        v-col(cols="12" md="12")
          v-layout
            formStepper(:model ='model', :field='field' :onSave='updateItem' :context='currentContext' @cancel="cancel")
</template>
<script>
  import Stepper from '@/components/custom/steppers/ProfileStepper'
  export default {
    components: {
      formStepper: Stepper,
    },
    props: {
      model: Object,
    },
    data: () => ({
      field: {
        types: 'update',
      },
      currentContext: 'admins',

    }),
    computed: {},
    created () {},
    methods: {
      updateItem () {
        const vm = this
        const itemData = vm.model
        // console.log(itemData)
        this.$forceUpdate()
        setTimeout(function () {
          // console.log(this.currentContext, itemData)
          this.$store.dispatch('users/updateProfile', itemData).then(() => {
            vm.onSuccess()
          })
        }.bind(this), 1000)
      },
      onSuccess () {
        this.$store.commit('users/SET_LOADING', false)
        this.$emit('close')
      },
      cancel () {
        this.$emit('close')
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .ql-video {
        max-width: 100%;

    }
    img{
        max-width: 100%;
        height:auto;
    }
</style>
