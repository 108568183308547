<template lang='pug'>
  v-container(pa-0='' )
    v-form(ref="form")
      v-container.py-0
        v-row
          v-col(cols="12" md="4")
              input.d-none(ref="file" type="file" @change="onChange")
              v-card.mx-auto.mt-0.d-inline-flex.v-card--account(:class="model.image ? 'success--text' : 'grey--text'" outlined="" height="106" width="106" @click="$refs.file.click()")
                  v-img(v-if='model.image' :src="model.image" height="100%" width="100%").custom-avatar
                  v-icon.mx-auto(v-else="" size="96")
                      | mdi-account
              .font-weight-bold.grey--text
                  | Choose Picture
          v-col(cols="12" md="8")
              v-col(cols="12" md="12")
                  validation-provider(v-slot="{ errors }" rules="required" name="Firts Name")
                      v-text-field(label="First Name" v-model="model.first_name" :error-messages="errors" color="secondary" validate-on-blur="")
              v-col(cols="12" md="12")
                  validation-provider(v-slot="{ errors }" rules="required" name="Last Name")
                      v-text-field(label="Last Name" v-model="model.last_name" :error-messages="errors" color="secondary" validate-on-blur="")
        v-row
          v-col(cols="12" md="6")
              validation-provider(v-slot="{ errors }" rules="required" name="Notification email")
                  v-text-field(label="Notification email" v-model="model.email" :error-messages="errors" color="secondary" validate-on-blur="")
          v-col(cols="12" md="6")
              validation-provider(v-slot="{ errors }" rules="required" name="Phone")
                  v-text-field(label="Phone" v-model="model.phone" :error-messages="errors" color="secondary" validate-on-blur="" type="number")
    v-btn(dark link @click="cancel()") Cancel
    v-btn(color='primary', :loading="isLoading" ,@click='save' :disabled='!model.first_name || !model.last_name || !model.email')
        | Save
</template>
<script>
  import firebase from 'firebase'
  import imageCompression from 'browser-image-compression'
  export default {
    props: {
      model: Object,
      field: Object,
      onSave: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        roles: ['Businesses', 'Contributor'],
      }
    },
    computed: {

      isLoading () {
        return this.$store.getters['users/getLoading']
      },

      userData () {
        // console.log(// console.log(firebase.auth().currentUser))
        return firebase.auth().currentUser
      },

    },
    methods: {
      uploadToServer (file) {
        const vm = this
        const storageRef = firebase.storage().ref()
        const metadata = {
          contentType: file.type,

          customMetadata: {
            targetType: file.type,
            targetId: vm.userData.uid,
            targetName: file.name,
          },
        }
        // Push to child path.
        // [START oncomplete]
        storageRef.child(`users/${vm.userData.uid}/profile/picture`).put(file, metadata).then(function (snapshot) {
          snapshot.ref.getDownloadURL().then(function (url) {
            vm.model.image = url
          })
        }).catch(function (error) {
          console.error('Upload failed:', error)
        })
      },
      async onChange (val) {
        const vm = this
        const value = val.target.files[0]

        if (!value) return (this.model.image = null)

        this.model.image = URL.createObjectURL(value)

        const imageFile = val.target.files[0]
        // console.log('originalFile instanceof Blob', imageFile instanceof Blob) // true
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)

        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 200,
          useWebWorker: true,
          maxIteration: 12,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options)
          // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
          // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
          if (imageFile.size > compressedFile.size) {
            // console.log('compressed is smaller')
            await vm.uploadToServer(compressedFile)
          } else {
            // console.log('original is smaller')
            await vm.uploadToServer(imageFile)
          } // if original is smaller
        } catch (error) {
          // console.log(error)
        }
      },
      cancel () {
        this.$emit('cancel')
      },
      save () {
        this.$store.commit('users/SET_LOADING', true)
        this.onSave()
      },
    },
  }
</script>
<style scoped lang="scss">
    $break-large: 1200px;
    $primary-color: #c29f52;
    .grid-picker{
        text-align: center;
        min-width: 100%;
        display: grid;
        position: relative;
        flex: 1;
        width: 100%;
        grid-template-areas:

                'picture picture '
                'title title '
                'button button';
        @media screen and (min-width: $break-large) {
            grid-template-areas:

                    'title picture picture '
                    'title picture picture '
                    'button button button';
            .image-slot{
                min-width: 400px;
                height: 250px;
                overflow: hidden;
            }
        }

        grid-gap: 10px;

        padding: 10px;
        .picker-btn{

            grid-area: button;
            flex: 1;
            height: 50px;
        }
        .image-slot{
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            display: flex;
            grid-area: picture;
            max-width: 350px;
            max-height: 250px;
            background: #9e9e9e;
            align-items:center;
            justify-content: center;

        }
    }

    .phone-screen{

        background: url("/img/phone.png");
        display:flex;
        flex-direction: column;
        border-radius: 50px;
        border: 1px solid black;

        min-width: 379px;
        min-height: 736px;
        background-size:  379px 736px;
        background-repeat: no-repeat;
        margin:auto;
        overflow: hidden;
        &__content{

            overflow-y: auto;
            max-width: 382px;
            max-height: 595px;
            margin: auto !important;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 0 15px;
        }
    }
    .main-carousel{
        position: relative;
        &__overlay{
            position: absolute;
            bottom:0;
            left:0;
            right:0;
            height: 45px;
            width: 100%;
            margin: auto !important;
            background:rgba(#000,0.5);
            color: #fff;
            text-align: center;

        }
    }
    .property-values{
        border: 1px solid #c29f52;
        border-radius: 30px;
        height: auto;
        padding: 0 10px;
        h3{
            color:#c29f52;
            font-weight: 900;

        }
        .price-tooltip{
            color:#c29f52;
            font-weight: bold;
            margin:auto;
            margin-top:-10px;
            max-width: 100px;
            border: 1px solid #c29f52;
            border-radius: 15px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top: none;
        }
    }
    .numPayments{
        background: white !important;
        border: 1px solid #777;
        border-radius: 25px;
        width:auto;
        max-width: fit-content;

        &__button{
            color:#000 !important;
            border-radius: 40px;
        }
    }
    .v-input--slider{
        margin-top:0;
    }
    .v-tabs__container{
        max-width: 100%;
        overflow-x: auto;

    }
    .property-sections{
        span,b{
            font-size: 15px !important;
        }

        .v-tab{
            color: black !important;
        }
    }
    .property-toolbar{

    }
</style>
